<template>
  <div>
    <!-- cookie安全扫描页面 -->
    <p>{{weeksDate[0].year + '-' + weeksDate[0].month}}</p>
    <div class="weeklyCalendar">
      <div @click="prevWeek"><span>前</span></div>
      <div v-for="(item, index) in weeksDate" :key="index" @click="changeDate(item.year, item.month, item.date)" :class="{'bg': Year == item.year && Month == item.month && Day == item.date}">
        <p>{{weeksTitle[index]}}</p>
        <p>{{item.date}}</p>
      </div>
      <div @click="nextWeek"><span>后</span></div>
    </div>
    <p>选中的日期：{{checkDate}}</p>
    <p>倒计时</p>
    <div v-for="(item, index) in timeList" :key="index">{{item.endTime}}---{{content}}</div>
  </div>
</template>

<script>
export default {
  name: 'Test',
  data() {
    return {
      list: [],
      weeksTitle: ['日', '一', '二', '三', '四', '五', '六'],
      Year: '',
      Month: '',
      Day: '',
      currentDay: '',
      weekNum: 0,
      weeksDate: [],
      checkDate: '',
      timeList: [{
        endTime: '2022-11-16 13:30',
      }],
      content: ''
    }
  },
  created() {
    this.$axios.get('/Api/Api/Web/GetWxUser/GetDitWebCodeList').then(res => {
        this.list = res.Data;
    })

    this.initWeeklyCalendar();
    let s = this.countDowm('2022-11-14 17:30');
    console.log(s)
  },
  methods: {
    initWeeklyCalendar() {
      let _date = new Date();
      this.Year = _date.getFullYear();
      this.Month = _date.getMonth() + 1;
      this.Day = _date.getDate();
      this.currentDay = _date.getDay();
      this.createDate(-this.currentDay);
      this.checkDate = this.Year + '-' + this.Month + '-' + this.Day;
      console.log('year：', this.Year);
      console.log('month', this.Month);
      console.log('day', this.Day)
      console.log('周几', this.currentDay)
    },
    countTime(n) {
      let _date = new Date();
      let getTime = _date.getTime() + (24 * 60 * 60 * 1000) * n;
      let needDate = new Date(getTime);
      let getYear = needDate.getFullYear();
      let getMonth = needDate.getMonth() + 1;
      let getDate = needDate.getDate();
      let obj = {
        'year': getYear,
        'month': getMonth,
        'date' : getDate
      };
      return obj
    },
    createDate(cDay) {
      this.weeksDate = [];
      for (let i = cDay; i < cDay + 7; i ++) {
        let data = this.countTime(i);
        this.weeksDate.push(data);
      }
    },
    format(num) {
      let formatNum = num < 10 ? '0' + num : num;
      return formatNum;
    },
    // 切换周
    changeWeek(weekNum) {
      this.createDate(- this.currentDay + (7 * weekNum));
    },
    // 上一周
    prevWeek() {
      this.weekNum --;
		  this.changeWeek(this.weekNum);
    },
    // 下一周
    nextWeek() {
      this.weekNum ++;
		  this.changeWeek(this.weekNum);
    },
    // 切换日期
    changeDate(y, m, d) {
      this.checkDate = y + '-' + m +'-' + d;
    },
    countDowm(timestamp){
      let timer = setInterval(() => {
        let nowTime = new Date();
        let endTime = new Date(timestamp);
        let t = endTime.getTime() - nowTime.getTime();
        if(t>0){
          let day = Math.floor(t/86400000);
          let hour=Math.floor((t/3600000)%24);
          let min=Math.floor((t/60000)%60);
          let sec=Math.floor((t/1000)%60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = '';
          if(day > 0){
            format =  `${day}天${hour}小时${min}分${sec}秒`;
          }
          if(day <= 0 && hour > 0 ){
            format = `${hour}小时${min}分${sec}秒`;
          }
          if(day <= 0 && hour <= 0){
            format =`${min}分${sec}秒`;
          }
          this.content =  format;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.weeklyCalendar {
  display: flex;
  div {
    flex: 1;
    // width: 80px;
    height: 100px;
    background-color: #eeeeee;
  }
  div:not(:last-of-type) {
    margin-right: 8px;
  }
  .bg {
    background-color: #CA001B;
  }
}
</style>